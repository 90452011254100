<template>
<!-- class="vld-parent" -->
  <div class="container h-100 w-100">
    <router-view />
  </div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    },
}
</script>


<style>

body {
  background: #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  height: 100%;
  width: 100%;;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

</style>
