<template>
  <div class="home h-100">
    <div class="row h-100">
      <div class="col-xl-8 offset-xl-2">
        <div id="log_in_form" class="d-flex flex-column justify-content-center align-items-center background-image h-100">
        <div class="row">
          <div class="col-sm-8 p-0 card background-text">
            <div class="card-body">
            <img class="w-100 bg-white text-dark" src="../../public/images/IFCA_logo.png">
              <!-- <img class="w-50" src="https://s3.ap-southeast-1.amazonaws.com/resources.wobbjobs.com/uploads/company-logo/company_logo_image/56bd3670-4bc6-4b30-a994-b760ad62629b/ifca-msc-berhad-1598515743.png"> -->
              <form @submit="addTodoItem">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="username"
                    name="username"
                    id="username"
                    placeholder="Username"
                  />
                  <label for="username" class="form-label">Username</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="emnail"
                    class="form-control"
                    v-model="username"
                    name="username"
                    id="email"
                    placeholder="Email"
                  />
                  <label for="email" class="form-label">Email</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                  />
                  <label for="password" class="form-label">Password</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="password"
                    name="password"
                    id="confirm_password"
                    placeholder="Password"
                  />
                  <label for="confirm_password" class="form-label">Confirm Password</label>
                </div>
                
                <div class="">
                  <button type="submit" class="w-75 btn btn-primary">Register</button>
                </div>
                <small><router-link to="/" class="w-75">Back to Login</router-link></small>
                <!-- <div class="mb-3">
                  <button type="submit" class="w-50 btn btn-primary">Register</button>
                </div>
                <router-link to="/" class="w-50 btn btn-outline-primary">Back to Login</router-link> -->
              </form>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  data() {
    return {
      fullPage: false,
    };
  },
  created() {},
};
</script>

<style scoped>
.home {
  /* width: 100%;
  height: 100%;; */
}

.loading_screen {
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
}

.loading_screen > div {
  background: url("https://giffiles.alphacoders.com/887/8871.gif") no-repeat
    fixed center;
}

#log_in_form {
  background: url(https://necessarydisorder.files.wordpress.com/2017/09/agif34.gif?w=982) no-repeat fixed center cover;
  background-size: 100% 100%;
}

.background-image {
  background-image: url("https://necessarydisorder.files.wordpress.com/2017/09/agif34.gif?w=982");
  background-color: #cccccc;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.background-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: white; */
}
</style>